import MiniProfile from '../user/MiniProfile'

export default function Navbar() {
	return (
		<nav className='flex-shrink-0 bg-primary-500'>
			<div className='max-w-7xl mx-auto py-3'>
				<div className='z-5 ml-2 flex justify-between'>
					<div></div>
					<MiniProfile></MiniProfile>

				</div>
			</div>
		</nav>
	)
}
