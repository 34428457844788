export default function Input(props) {
	return (
		<>
			<textarea
				value={props.value}
				placeholder={props.placeholder}
				className="outline-none w-full border border-gray-300 rounded-xl p-3"
				onChange={(e) => {
					if (props.setValue) {
						props.setValue(e.target.value)
					}
				}}></textarea>
		</>
	)
}
